export default function Section4() {
  return (
    <div className="bg-[#121212] md:py-24 section4 relative font-inter md:my-20">
      <div className="absolute inset-0 flex md:pl-16 lg:pl-40 items-center">
        <h1 className="text-white opacity-10 text-[350px] font-berlin select-none">
          &lt;S&gt;
        </h1>
      </div>

      <div className="relative z-10 text-center md:text-start px-1 md:px-8 lg:pl-80">
        <h1 className="bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50 text-4xl font-bold drop-shadow-lg">
          Website-ul tau este{" "}
          <span className="text-green-500">agentul tau de vanzari</span>{" "}
          disponibil 24/7.
          <br /> Nu te multumi cu unul obisnuit.
          <br /> Depaseste <span className="italic">asteptarile</span> cu{" "}
          <span className="text-green-500">South Agency.</span>
        </h1>
      </div>
    </div>
  );
}
