import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { OrbitingCirclesDemo } from "./OrbitingDemo";

export default function Section2() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);

  return (
    <motion.section
      ref={ref}
      className="bg-[#121212] py-12 md:px-40 min-[1337px]:flex justify-center text-center md:text-start items-center"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 50 }}
      transition={{ duration: 0.8, delay: 0.2 }}
    >
      <div className="flex flex-col mx-auto max-w-xl px-8 md:px-0 min-[1517px]:max-w-3xl">
        <h2 className="text-4xl font-bold text-neutral-300 mb-8">
          Cine suntem?
        </h2>
        <p className="text-xl text-neutral-400 mb-6">
          South Agency este o agenție de Web Development{" "}
          <span className="italic">nouă pe piață</span>, dar cu o echipă de
          profesioniști cu experiență în domeniu.
        </p>
        <h2 className="text-4xl font-bold text-neutral-300 mb-8">
          Cu ce suntem <span className="italic">diferiți</span> față de alte
          agenții?
        </h2>
        <p className="text-xl text-neutral-400 mb-6">
          Majoritatea agențiilor de web development folosesc tool-uri cum ar fi
          WordPress sau Wix pentru a crea site-uri web. Noi, pe de altă parte,
          folosim tehnologii precum React.js, Next.js pentru a crea site-uri web
          <span className="italic"> rapide</span> și{" "}
          <span className="italic">scalabile</span>. <br /> <br /> Tot odata
          fiind la inceputul drumului nostru, putem oferi{" "}
          <span className="italic">preturi mai accesibile</span> fata de alte
          agentii.
        </p>
      </div>
      <OrbitingCirclesDemo />
    </motion.section>
  );
}
