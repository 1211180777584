import { CardSpotlight } from "./card-spotlight";

export default function Section() {
  return (
    <section className="md:my-12 my-24 px-4 md:px-32 xl:px-72 text-center font-inter">
      <div className="flex justify-items-center mb-8 gap-4">
        <img src="/quest.png" alt="questionmark" className="w-14 self-center" />
        <h2 className="text-4xl font-bold self-center text-neutral-100">
          De ce să ne alegi pe noi?
        </h2>
      </div>
      <div className="grid md:grid-cols-2 gap-8">
        <CardSpotlight className="text-start text-neutral-100 bg-black/15 border-2 rounded-md border-white/20 p-6 hover:bg-black/40">
          <h2 className="text-xl font-extrabold relative z-20">
            Suntem perfectionisti
          </h2>
          <p className="mt-4 max-w-md relative z-20">
            Mereu ne dorim ca proiectul nostru sa arate cat mai bine posibil, sa
            depaseasca asteptarile tuturor.
          </p>
        </CardSpotlight>
        <CardSpotlight className="text-start text-neutral-100 bg-black/15 border-2 rounded-md border-white/20 p-6 hover:bg-black/40">
          <h2 className="text-xl font-extrabold relative z-20">
            Raport calitate-pret
          </h2>
          <p className="mt-4 max-w-md relative z-20">
            Oferim cel mai bun raport calitate-pret, astfel asta ar fi sansa
            perfecta sa profiti.
          </p>
        </CardSpotlight>
      </div>

      {/*<ul className="space-y-8 ">
        <li className="flex items-center space-x-2 bg-[#0bc77f] p-4 listitm hover:bg-[#02975e] transition-colors duration-500">
          <svg
            className="text-white mr-2 w-[2.5rem] h-[2.5rem] listicon"
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            viewBox="0 0 16 16"
          >
            <path
              fill="currentColor"
              d="M12 7V5l-1.2-.4c-.1-.3-.2-.7-.4-1l.6-1.2l-1.5-1.3l-1.1.5c-.3-.2-.6-.3-1-.4L7 0H5l-.4 1.2c-.3.1-.7.2-1 .4l-1.1-.5l-1.4 1.4l.6 1.2c-.2.3-.3.6-.4 1L0 5v2l1.2.4c.1.3.2.7.4 1l-.5 1.1l1.4 1.4l1.2-.6c.3.2.6.3 1 .4L5 12h2l.4-1.2c.3-.1.7-.2 1-.4l1.2.6L11 9.6l-.6-1.2c.2-.3.3-.6.4-1L12 7zM3 6c0-1.7 1.3-3 3-3s3 1.3 3 3s-1.3 3-3 3s-3-1.3-3-3z"
            />
            <path
              fill="currentColor"
              d="M7.5 6a1.5 1.5 0 1 1-3.001-.001A1.5 1.5 0 0 1 7.5 6zM16 3V2h-.6c0-.2-.1-.4-.2-.5l.4-.4l-.7-.7l-.4.4c-.2-.1-.3-.2-.5-.2V0h-1v.6c-.2 0-.4.1-.5.2l-.4-.4l-.7.7l.4.4c-.1.2-.2.3-.2.5H11v1h.6c0 .2.1.4.2.5l-.4.4l.7.7l.4-.4c.2.1.3.2.5.2V5h1v-.6c.2 0 .4-.1.5-.2l.4.4l.7-.7l-.4-.4c.1-.2.2-.3.2-.5h.6zm-2.5.5c-.6 0-1-.4-1-1s.4-1 1-1s1 .4 1 1s-.4 1-1 1zm1.9 8.3c-.1-.3-.2-.6-.4-.9l.3-.6l-.7-.7l-.5.4c-.3-.2-.6-.3-.9-.4L13 9h-1l-.2.6c-.3.1-.6.2-.9.4l-.6-.3l-.7.7l.3.6c-.2.3-.3.6-.4.9L9 12v1l.6.2c.1.3.2.6.4.9l-.3.6l.7.7l.6-.3c.3.2.6.3.9.4l.1.5h1l.2-.6c.3-.1.6-.2.9-.4l.6.3l.7-.7l-.4-.5c.2-.3.3-.6.4-.9l.6-.2v-1l-.6-.2zM12.5 14c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5s1.5.7 1.5 1.5s-.7 1.5-1.5 1.5z"
            />
          </svg>
          <span className="text-[#121212] font-medium transition-all duration-500 listspan">
            <span className="text-white font-semibold text-xl">
              Ne adaptăm cerințelor tale și îți oferim soluții personalizate.
            </span>
            <br />
            Fiecare proiect este unic și tratat cu atenție și dedicație.
          </span>
        </li>
        <li className="flex items-center space-x-2 bg-[#0bc77f] p-4 listitm hover:bg-[#02975e] transition-colors duration-500">
          <svg
            className="text-white mr-2 w-[2.5rem] h-[2.5rem] listicon"
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            viewBox="0 0 15 15"
          >
            <path
              fill="currentColor"
              d="M11.555 2C9.41 2 6.534 3.471 4.602 6H3C1.843 6 1.18 6.864.723 7.777L.11 9H3l1.5 1.5L6 12v2.889l1.223-.612C8.136 13.821 9 13.157 9 12v-1.602c2.529-1.932 4-4.809 4-6.953V2zM9 5a1 1 0 1 1 0 2a1 1 0 0 1 0-2zm-6.5 6l-.5.5c-.722.722-1 2.5-1 2.5s1.698-.198 2.5-1l.5-.5z"
            />
          </svg>

          <span className="text-[#121212] font-medium transition-all duration-500 listspan">
            <span className="text-white font-semibold text-xl">
              Folosim cele mai noi tehnologii pentru a crea un website
              performant.
            </span>
            <br />
            Te asigurăm de un site rapid, securizat și ușor de utilizat.
          </span>
        </li>
        <li className="flex items-center space-x-2 bg-[#0bc77f] p-4 listitm hover:bg-[#02975e] transition-colors duration-500">
          <svg
            className="text-white mr-2 w-[2.5rem] h-[2.5rem] listicon"
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            viewBox="0 0 24 24"
          >
            <g fill="none" fill-rule="evenodd">
              <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01l-.184-.092Z" />
              <path
                fill="currentColor"
                d="m22.017 3.874l-.59 1.581a250.264 250.264 0 0 1-.732 1.697c-.678 1.455-1.612 3.156-2.727 4.33c-1.066 1.12-2.674 2.153-3.962 2.886a4.996 4.996 0 0 1-1.36 4.557c-2.27 2.27-4.806 2.27-6.692 1.71c-1.43-.426-2.606-1.198-3.73-2.148a.394.394 0 0 1 .027-.624l.5-.362c.672-.507 1.325-1.126 1.49-1.955c.115-.925.18-1.43.197-1.513c.138-.689.43-1.471 1.138-2.18a4.996 4.996 0 0 1 4.556-1.36c.733-1.287 1.766-2.895 2.886-3.96c1.174-1.116 2.875-2.05 4.33-2.728l1.697-.733l1.581-.589a1.094 1.094 0 0 1 1.391 1.39ZM11.98 11.293a5.041 5.041 0 0 1 1.227 1.228c.392-.227.795-.473 1.19-.732l-.03-.067a3.312 3.312 0 0 0-.66-.93a3.354 3.354 0 0 0-.817-.603l-.179-.086c-.259.395-.505.798-.732 1.19Zm7.442-6.215c-.383.159-.8.34-1.23.54c-1.408.657-2.866 1.48-3.796 2.364a7.63 7.63 0 0 0-.47.493c.362.2.782.49 1.195.904c.414.413.704.833.904 1.195c.178-.156.344-.313.493-.47c.884-.93 1.707-2.388 2.364-3.797c.2-.43.381-.846.54-1.229Z"
              />
            </g>
          </svg>
          <span className="text-[#121212] font-medium transition-all duration-500 listspan">
            <span className="text-white font-semibold text-xl">
              Îți oferim un design modern și atractiv, care să îți reprezinte
              brandul.
            </span>
            <br /> Creăm identități vizuale care atrag și rețin atenția.
          </span>
        </li>
        <li className="flex items-center space-x-2 bg-[#0bc77f] p-4 listitm hover:bg-[#02975e] transition-colors duration-500">
          <svg
            className="text-white mr-2 w-[2.5rem] h-[2.5rem] listicon"
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            viewBox="0 0 100 100"
          >
            <path
              id="gisGlobeUsers0"
              fill="currentColor"
              stroke-dashoffset="188.976"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="3.644"
              d="M49.947 0C22.354.03 0 22.406 0 50a50 50 0 0 0 20.404 40.21c-.265-2.031-.213-4.128.117-6.202a45.22 45.22 0 0 1-8.511-9.877h12.445c1.182-1.845 2.4-3.67 4.525-5c-1.245-5.1-2.006-10.716-2.146-16.631h1.346a18.653 18.653 0 0 1 1.93-5h-3.243c.212-5.935 1.043-11.554 2.363-16.63H47.5v8.888a13.75 13.75 0 0 1 5 1.804V30.87h19.195c.26.997.495 2.02.715 3.057a19.769 19.769 0 0 1 5.084-.117a76.416 76.416 0 0 0-.639-2.94h13.89a44.747 44.747 0 0 1 3.965 14.028c.58 5.049.591 10.975-1.246 16.771a45.007 45.007 0 0 1-2.286 6.478c1.128 1.187 2.494 2.309 3.867 3.454A50 50 0 0 0 100 50c0-27.614-22.386-50-50-50ZM52.5 5.682c5.268.896 10.302 5.236 14.268 12.437c1.278 2.321 2.42 4.927 3.408 7.75H52.5Zm-5 .197v19.99H30.75c.988-2.823 2.13-5.429 3.408-7.75C37.89 11.341 42.571 7.102 47.5 5.88ZM35.98 7.232c-2.324 2.352-4.41 5.22-6.203 8.475c-1.68 3.05-3.125 6.467-4.312 10.162H12.01c5.535-8.706 13.975-15.37 23.97-18.637Zm29.41.463c9.398 3.413 17.32 9.868 22.6 18.174H75.455c-1.184-3.695-2.627-7.112-4.307-10.162c-1.676-3.045-3.613-5.749-5.757-8.012ZM9.257 30.87h14.808c-1.245 5.162-2.008 10.76-2.203 16.631H5.072a44.79 44.79 0 0 1 4.184-16.63ZM5.072 52.5h16.762c.129 5.856.82 11.454 1.994 16.63H9.256A44.79 44.79 0 0 1 5.072 52.5Z"
              color="currentColor"
            />
            <path
              id="gisGlobeUsers1"
              fill="currentColor"
              fill-opacity="1"
              stroke="none"
              stroke-dasharray="none"
              stroke-dashoffset="4"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="4"
              stroke-opacity="1"
              stroke-width="3.621"
              d="M76 37.769c-8.285 0-15 6.716-15 15c0 8.284 6.715 15 15 15c8.283 0 15-6.716 15-15c0-8.284-6.717-15-15-15zm0 32.223c-16.57 0-24 7.431-24 24v2c.075 3.94 1.817 4.056 5.5 4h37c4.695-.004 5.532.005 5.5-4v-2c0-16.569-7.432-24-24-24z"
              opacity="1"
              vector-effect="none"
            />
            <path
              id="gisGlobeUsers2"
              fill="currentColor"
              fill-opacity="1"
              stroke="none"
              stroke-dasharray="none"
              stroke-dashoffset="4"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-miterlimit="4"
              stroke-opacity="1"
              stroke-width="2.966"
              d="M44 43.39c-6.787 0-12.291 5.504-12.291 12.292c0 6.787 5.504 12.289 12.291 12.289c6.787 0 12.29-5.502 12.29-12.29c0-6.787-5.503-12.29-12.29-12.29zm0 26.401c-13.575 0-19.664 6.09-19.664 19.664v1.639c.062 3.228 1.489 3.323 4.506 3.277h19.123c-.488-8.088 1.901-16.678 7.851-22.139c-3.012-1.646-6.925-2.441-11.816-2.441z"
              opacity="1"
              vector-effect="none"
            />
          </svg>
          <span className="text-[#121212] font-medium transition-all duration-500 listspan">
            <span className="text-white font-semibold text-xl">
              Folosim experiența noastră vastă pentru a aduce valoare
              proiectelor tale.
            </span>
            <br />
            <span>Suntem noi pe piață, dar nu suntem noi în domeniu.</span>
          </span>
        </li>
        <li className="flex items-center space-x-2 bg-[#0bc77f] p-4 listitm hover:bg-[#02975e] transition-colors duration-500">
          <svg
            className="text-white mr-2 w-[2.5rem] h-[2.5rem] listicon"
            xmlns="http://www.w3.org/2000/svg"
            width="200"
            height="200"
            viewBox="0 0 24 24"
          >
            <path
              fill="currentColor"
              d="M14 11h-4a2 2 0 0 1 0-4h5a1 1 0 0 1 1 1a1 1 0 0 0 2 0a3 3 0 0 0-3-3h-2V3a1 1 0 0 0-2 0v2h-1a4 4 0 0 0 0 8h4a2 2 0 0 1 0 4H9a1 1 0 0 1-1-1a1 1 0 0 0-2 0a3 3 0 0 0 3 3h2v2a1 1 0 0 0 2 0v-2h1a4 4 0 0 0 0-8Z"
            />
          </svg>
          <span className="text-[#121212] font-medium transition-all duration-500 listspan">
            <span className="text-white font-semibold text-xl">
              Fiind la început, prețurile noastre sunt competitive, deci poți
              profita de această ocazie.
            </span>
            <br /> Oferim calitate superioară la prețuri avantajoase.
          </span>
        </li>
      </ul>*/}
    </section>
  );
}
