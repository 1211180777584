import { Link } from "react-router-dom";
import ShimmerButton from "./shimmer-button";

export default function Header() {
  return (
    <nav className="py-4 fixed w-full transition-colors duration-300 nav-scrolled">
      <div className="navbar_container md:px-20 xl:px-52">
        <Link to="/">
          <h1 className="text-white text-3xl font-berlin select-none">
            <span className="text-[#0bc77f]">&lt;S&gt;</span>outhAgency
          </h1>
        </Link>
        <ul className="hidden md:flex justify-center items-center gap-8 text-md font-kanit text-white/50">
          <li className="hover-effect">
            <Link to="/">Pagina principala</Link>
          </li>
          <li className="hover-effect">
            <Link to="/servicii">Servicii</Link>
          </li>
        </ul>
        <div className="w-fit">
          <Link to="/contact">
            <ShimmerButton shimmerColor="#0bc77f">
              <span className="xl:whitespace-pre-wrap text-center text-sm font-medium leading-none tracking-tight text-white dark:from-white dark:to-slate-900/10 lg:text-lg">
                Contacteaza-ne
              </span>
            </ShimmerButton>
          </Link>
        </div>
      </div>
    </nav>
  );
}
