import { WobbleCard } from "./wobble-card";
import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";

export default function Section3() {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return (
    <motion.section
      ref={ref}
      className="hidden xl:grid grid-cols-1 lg:grid-cols-3 gap-4 max-w-7xl mx-auto w-full py-16"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: isVisible ? 1 : 0, y: isVisible ? 0 : 50 }}
      transition={{ duration: 0.8, delay: 0.2 }}
    >
      <WobbleCard
        containerClassName="col-span-1 lg:col-span-2 h-full bg-green-800 min-h-[500px] lg:min-h-[300px]"
        className=""
      >
        <div className="max-w-xs">
          <h2 className="text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">
            Nu iti pierde clientii din cauza unui site invechit.
          </h2>
          <p className="mt-4 text-left  text-base/6 text-neutral-200">
            Noi ne ocupam de tot, de la design pana la implementare si
            optimizare.
          </p>
        </div>
        <img
          src="/linear.png"
          width={500}
          height={500}
          alt="linear demo"
          className="absolute -right-4 lg:-right-[40%] grayscale filter -bottom-10 object-contain rounded-2xl"
        />
      </WobbleCard>
      <WobbleCard containerClassName="col-span-1 min-h-[300px] bg-green-700">
        <h2 className="max-w-80  text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">
          Ai un design in minte?
        </h2>
        <p className="mt-4 max-w-[26rem] text-left  text-base/6 text-neutral-200">
          Perfect, noi il analizam si il punem in aplicare. Daca nu, nu-ti face
          griji, avem o echipa de designeri care se ocupa de asta.
        </p>
      </WobbleCard>
      <WobbleCard containerClassName="col-span-1 lg:col-span-3 bg-green-900 min-h-[500px] lg:min-h-[600px] xl:min-h-[300px]">
        <div className="max-w-sm">
          <h2 className="max-w-sm md:max-w-lg  text-left text-balance text-base md:text-xl lg:text-3xl font-semibold tracking-[-0.015em] text-white">
            Contacteaza-ne acum pentru a incepe colaborarea.
          </h2>
          <p className="mt-4 max-w-[26rem] text-left  text-base/6 text-neutral-200">
            Suntem aici pentru tine, pentru a-ti oferi cele mai bune servicii.
          </p>
        </div>
        <img
          src="/linear.png"
          width={500}
          height={500}
          alt="linear demo"
          className="absolute -right-10 md:-right-[40%] lg:-right-[20%] -bottom-10 object-contain rounded-2xl"
        />
      </WobbleCard>
    </motion.section>
  );
}
