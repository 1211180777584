import React from "react";
import BlurIn from "./blur-in";

export default function Hero() {
  return (
    <div className="hero-container mt-6 md:mt-0">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 1080"
        preserveAspectRatio="xMidYMid slice"
      >
        <defs>
          <radialGradient
            id="a-0"
            gradientUnits="objectBoundingBox"
            x1="0"
            y1="0"
            x2="100"
            y2="100"
          >
            <stop offset="0" stopColor="hsla(132,0%,7%,1)">
              <animate
                attributeName="stop-color"
                dur="20s"
                repeatCount="indefinite"
                values="hsla(132,0%,7%,1);hsla(157.0212765957447,0%,7%,1);hsla(132,0%,7%,1)"
              ></animate>
            </stop>
            <stop
              offset="1"
              stopColor="hsla(132,0%,7%,0)"
              stopOpacity="0"
            ></stop>
          </radialGradient>
          <radialGradient
            id="a-1"
            gradientUnits="objectBoundingBox"
            x1="0"
            y1="0"
            x2="100"
            y2="100"
          >
            <stop offset="0" stopColor="hsla(109.99999999999999,0%,7%,1)">
              <animate
                attributeName="stop-color"
                dur="20s"
                repeatCount="indefinite"
                values="hsla(109.99999999999999,0%,7%,1);hsla(157.0212765957447,0%,7%,1);hsla(109.99999999999999,0%,7%,1)"
              ></animate>
            </stop>
            <stop
              offset="1"
              stopColor="hsla(109.99999999999999,0%,7%,0)"
              stopOpacity="0"
            ></stop>
          </radialGradient>
          <radialGradient
            id="a-2"
            gradientUnits="objectBoundingBox"
            x1="0"
            y1="0"
            x2="100"
            y2="100"
          >
            <stop offset="0" stopColor="hsla(134.00000000000003,0%,7%,1)">
              <animate
                attributeName="stop-color"
                dur="20s"
                repeatCount="indefinite"
                values="hsla(134.00000000000003,0%,7%,1);hsla(157.0212765957447,0%,7%,1);hsla(134.00000000000003,0%,7%,1)"
              ></animate>
            </stop>
            <stop
              offset="1"
              stopColor="hsla(134.00000000000003,0%,7%,0)"
              stopOpacity="0"
            ></stop>
          </radialGradient>
          <radialGradient
            id="a-3"
            gradientUnits="objectBoundingBox"
            x1="0"
            y1="0"
            x2="100"
            y2="100"
          >
            <stop offset="0" stopColor="hsla(135.00000000000026,0%,7%,1)">
              <animate
                attributeName="stop-color"
                dur="20s"
                repeatCount="indefinite"
                values="hsla(135.00000000000026,0%,7%,1);hsla(157.0212765957447,0%,7%,1);hsla(135.00000000000026,0%,7%,1)"
              ></animate>
            </stop>
            <stop
              offset="1"
              stopColor="hsla(135.00000000000026,0%,7%,0)"
              stopOpacity="0"
            ></stop>
          </radialGradient>
          <radialGradient
            id="a-4"
            gradientUnits="objectBoundingBox"
            x1="0"
            y1="0"
            x2="100"
            y2="100"
          >
            <stop offset="0" stopColor="hsla(139,0%,7%,1)">
              <animate
                attributeName="stop-color"
                dur="20s"
                repeatCount="indefinite"
                values="hsla(139,0%,7%,1);hsla(157.0212765957447,0%,7%,1);hsla(139,0%,7%,1)"
              ></animate>
            </stop>
            <stop
              offset="1"
              stopColor="hsla(139,0%,7%,0)"
              stopOpacity="0"
            ></stop>
          </radialGradient>
        </defs>
        <rect
          fill="hsla(157.0212765957447,89%,41%,1)"
          width="100%"
          height="100%"
        ></rect>
        <ellipse cx="46%" cy="14%" ry="60%" rx="65%" fill="url(#a-4)">
          <animate
            attributeName="cy"
            dur="20s"
            repeatCount="indefinite"
            values="14%;6%;14%"
          ></animate>
          <animate
            attributeName="cx"
            dur="20s"
            repeatCount="indefinite"
            values="46%;32%;46%"
          ></animate>
        </ellipse>
        <ellipse cx="42%" cy="59%" ry="60%" rx="65%" fill="url(#a-3)">
          <animate
            attributeName="cy"
            dur="20s"
            repeatCount="indefinite"
            values="59%;20%;59%"
          ></animate>
          <animate
            attributeName="cx"
            dur="20s"
            repeatCount="indefinite"
            values="42%;66%;42%"
          ></animate>
        </ellipse>
        <ellipse cx="15%" cy="63%" ry="60%" rx="65%" fill="url(#a-2)">
          <animate
            attributeName="cy"
            dur="20s"
            repeatCount="indefinite"
            values="63%;80%;63%"
          ></animate>
          <animate
            attributeName="cx"
            dur="20s"
            repeatCount="indefinite"
            values="15%;30%;15%"
          ></animate>
        </ellipse>
        <ellipse cx="35%" cy="86%" ry="60%" rx="65%" fill="url(#a-1)">
          <animate
            attributeName="cy"
            dur="20s"
            repeatCount="indefinite"
            values="86%;5%;86%"
          ></animate>
          <animate
            attributeName="cx"
            dur="20s"
            repeatCount="indefinite"
            values="35%;91%;35%"
          ></animate>
        </ellipse>
        <ellipse cx="20%" cy="82%" ry="60%" rx="65%" fill="url(#a-0)">
          <animate
            attributeName="cy"
            dur="20s"
            repeatCount="indefinite"
            values="82%;55%;82%"
          ></animate>
          <animate
            attributeName="cx"
            dur="20s"
            repeatCount="indefinite"
            values="20%;49%;20%"
          ></animate>
        </ellipse>
      </svg>
      <div className="hero-text font-inter">
        <BlurIn
          word="Nu te mulțumi cu puțin."
          className="text-4xl md:text-6xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50"
        />
        <BlurIn
          word="Brand-ul tău merită mai mult."
          className="text-4xl md:text-6xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-neutral-50 to-neutral-400 bg-opacity-50"
        />

        <p className="text-2xl mt-4 text-[#87928f]">
          Noi credem că fiecare brand are o poveste unică de spus. Scopul nostru
          este să proiectăm și să dezvoltăm site-uri web care nu numai că atrag
          atenția vizitatorilor, dar contribuie și la beneficii tangibile ale
          succesului afacerii dumneavoastră.
        </p>
      </div>
    </div>
  );
}
