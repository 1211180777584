import Hero from "../components/Hero";
import Section2 from "../components/Section2";
import Section3 from "../components/Section3";
import Section4 from "../components/Section4";
import Section from "../components/Section";
import { useEffect, useState } from "react";

export default function Home() {
  const [isRendering, setIsRendering] = useState(true);
  useEffect(() => {
    setTimeout(() => setIsRendering(false), 1);
  }, []);
  return (
    <>
      {isRendering && <div></div>}
      {!isRendering && (
        <main>
          <Hero />
          <Section2 />
          <Section3 />
          <Section4 />
          <Section />
        </main>
      )}
    </>
  );
}
