import { cn } from "../lib/utils";
import {
  IconCloud,
  IconCurrencyDollar,
  IconRouteAltLeft,
  IconSeo,
  IconBrush,
  IconShield,
  IconApi,
  IconBolt,
} from "@tabler/icons-react";

export function FeaturesSectionDemo() {
  const features = [
    {
      title: "Web Development",
      description:
        "Noi dezvoltăm site-ul tău folosind cele mai noi tehnologii.",
      icon: <IconRouteAltLeft />,
    },
    {
      title: "Cel mai bun preț",
      description:
        "Oferim cel mai bun raport calitate-preț. Îți sugerăm să profiți.",
      icon: <IconCurrencyDollar />,
    },
    {
      title: "Optimizare SEO",
      description:
        "Folosim cele mai noi tehnici pentru a-ți crește vizibilitatea pe Google.",
      icon: <IconSeo />,
    },
    {
      title: "Web Design",
      description: "Oferim un design modern, responsive și ușor de folosit.",
      icon: <IconBrush />,
    },
    {
      title: "Mentenanță",
      description: "Oferim mentenanță oricând este nevoie.",
      icon: <IconCloud />,
    },
    {
      title: "Integrări API",
      description:
        "Facem site-ul tău compatibil cu diverse API-uri externe pentru funcționalități avansate.",
      icon: <IconApi />,
    },
    {
      title: "Optimizare Performanță",
      description:
        "Optimizăm viteza și performanța site-ului pentru o experiență excelentă a utilizatorului.",
      icon: <IconBolt />,
    },
    {
      title: "Securitate Avansată",
      description:
        "Implementăm măsuri de securitate de top pentru a proteja site-ul tău împotriva amenințărilor.",
      icon: <IconShield />,
    },
  ];

  return (
    <div className="relative z-10 py-10 max-w-7xl mx-auto">
      <div className="mb-12 px-5">
        <h2 className="text-6xl font-bold text-center mb-8 text-neutral-900 dark:text-neutral-100">
          Serviciile Noastre
        </h2>
        <p className="text-xl text-center text-neutral-600 dark:text-neutral-300">
          Agenția noastră de web development oferă o gamă largă de servicii
          menite să satisfacă toate nevoile tale online. De la dezvoltare web la
          optimizare SEO și design modern, echipa noastră de experți este aici
          pentru a transforma viziunea ta într-o realitate digitală. Explorează
          mai jos fiecare dintre serviciile noastre și descoperă cum putem să te
          ajutăm să îți atingi obiectivele.
        </p>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4">
        {features.map((feature, index) => (
          <Feature key={feature.title} {...feature} index={index} />
        ))}
      </div>
    </div>
  );
}

const Feature = ({
  title,
  description,
  icon,
  index,
}: {
  title: string;
  description: string;
  icon: React.ReactNode;
  index: number;
}) => {
  return (
    <div
      className={cn(
        "flex flex-col lg:border-r py-10 relative group/feature dark:border-neutral-800",
        (index === 0 || index === 4) && "lg:border-l dark:border-neutral-800",
        index < 4 && "lg:border-b dark:border-neutral-800"
      )}
    >
      {index < 4 && (
        <div className="opacity-0 group-hover/feature:opacity-100 transition duration-200 absolute inset-0 h-full w-full bg-gradient-to-t from-neutral-100 dark:from-neutral-800 to-transparent pointer-events-none" />
      )}
      {index >= 4 && (
        <div className="opacity-0 group-hover/feature:opacity-100 transition duration-200 absolute inset-0 h-full w-full bg-gradient-to-b from-neutral-100 dark:from-neutral-800 to-transparent pointer-events-none" />
      )}
      <div className="mb-4 relative z-10 px-10 text-neutral-600 dark:text-neutral-400">
        {icon}
      </div>
      <div className="text-lg font-bold mb-2 relative z-10 px-10">
        <div className="absolute left-0 inset-y-0 h-6 group-hover/feature:h-8 w-1 rounded-tr-full rounded-br-full bg-neutral-300 dark:bg-neutral-700 group-hover/feature:bg-[#0bc77f] transition-all duration-200 origin-center" />
        <span className="group-hover/feature:translate-x-2 transition duration-200 inline-block text-neutral-800 dark:text-neutral-100">
          {title}
        </span>
      </div>
      <p className="text-sm text-neutral-600 dark:text-neutral-300 max-w-xs relative z-10 px-10">
        {description}
      </p>
    </div>
  );
};
