import { MdOutlineEmail } from "react-icons/md";
import { LiaLongArrowAltRightSolid } from "react-icons/lia";
import { cn } from "../lib/utils";
import { GridPattern } from "../components/animated-grid";
import PulsatingButton from "../components/PulsatingButton";
import { useEffect, useState } from "react";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";

export default function Contact() {
  const serviceId = "service_5pa0uxz";
  const templateId = "template_svipipv";
  const publicKey = "UUe1n3AnFmGYKYws2";
  const recaptchaKey = "6Le3YEcqAAAAAE9ApCljUkMRyiDNpY3bhn5jILPS";

  const [nume, setNume] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [isRendering, setIsRendering] = useState(true);
  const [recaptchaValue, setRecaptchaValue] = useState(null);
  const [attempts, setAttempts] = useState(0);
  const [showRecaptcha, setShowRecaptcha] = useState(false);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  useEffect(() => {
    setTimeout(() => setIsRendering(false), 1);
    const storedAttempts = localStorage.getItem("formAttempts");
    if (storedAttempts) {
      setAttempts(Number(storedAttempts));
      if (Number(storedAttempts) >= 2) {
        setShowRecaptcha(true);
      }
    }
  }, []);

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (!nume || !phone || !email || !message) {
      setShowErrorAlert(true);
      setTimeout(() => {
        setShowErrorAlert(false);
      }, 3000);
      return;
    }

    const newAttempts = attempts + 1;
    setAttempts(newAttempts);

    localStorage.setItem("formAttempts", String(newAttempts));

    if (newAttempts >= 2 && !recaptchaValue) {
      alert("Te rugăm să completezi reCAPTCHA!");
      return;
    }

    if (newAttempts >= 2) {
      setShowRecaptcha(true);
    }

    const templateParams = {
      nume,
      phone,
      email,
      message,
    };

    setShowAlert(true);

    setTimeout(() => {
      setShowAlert(false);
    }, 3000);

    emailjs.send(serviceId, templateId, templateParams, publicKey).then(
      function (response) {
        console.log("SUCCESS!", response.status, response.text);
        setShowAlert(true);
        setNume("");
        setPhone("");
        setEmail("");
        setMessage("");
        setRecaptchaValue(null); // Resetează reCAPTCHA după trimitere
        setTimeout(() => {
          setShowAlert(false);
        }, 3000);
      },
      function (error) {
        console.log("FAILED...", error);
      }
    );
  };

  const onRecaptchaChange = (value: any) => {
    setRecaptchaValue(value);
  };

  return (
    <>
      {isRendering && <div></div>}

      {showErrorAlert && (
        <div className="z-50 gap-3 mt-24 px-8 py-4 border-2 border-white/20 rounded-xl fixed top-0 right-0 left-0 mx-auto w-3/4 transition-all duration-1000 ease-in-out text-[#ff0000] bg-black/80 md:bg-black/90 flex flex-row sm:w-fit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
          <span className="font-semibold">
            Te rugăm să completezi toate câmpurile!
          </span>
        </div>
      )}
      {showAlert && (
        <div className="z-50 gap-3 mt-24 px-8 py-4 border-2 border-white/20 rounded-xl fixed top-0 right-0 left-0 mx-auto w-3/4 transition-all duration-1000 ease-in-out text-[#0bc77f] bg-black/80 md:bg-black/15 flex flex-row sm:w-fit">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
          <span className="font-semibold">Mesajul a fost trimis!</span>
        </div>
      )}

      {!isRendering && (
        <div className="relative md:flex md:h-[700px] w-full items-center mt-28 justify-center overflow-hidden xl:pl-20 text-neutral-200 font-inter">
          <div className="contact-section z-10">
            <div className="xl:px-20 text-white px-5">
              <h1 className="text-5xl mb-8 font-bold text-center md:text-start">
                Incepe acum
              </h1>
              <p className="md:w-4/5 md:max-w-lg mb-10 text-center md:text-start">
                Colaboreaza cu South Agency pentru urmatorul tau website.
                Completeza formularul si spune-ne despre proiectul tau. Suntem
                aici sa transformam ideile tale intr-o experienta digitala
                uimitoare.
              </p>
              <hr className="border-t-2 border-t-white/30" />
              <p className="mt-10 mb-4 text-white/80 text-center md:text-start">
                Nu iti place sa completezi formulare?
              </p>
              <div className="flex gap-4 mb-10 justify-center md:justify-normal">
                <a
                  href="mailto:southagency2024@gmail.com"
                  className="bg-black/15 border-2 rounded-md border-white/20 py-2 px-8 flex items-center hover:bg-black/40"
                >
                  <MdOutlineEmail className="inline-block mr-2" />
                  Email
                </a>
                <a
                  href="https://www.facebook.com/southagencyro/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <PulsatingButton children="Facebook" />
                </a>
              </div>
              <hr className="border-t-2 border-t-white/30" />
            </div>
            <div className="flex mx-auto rounded-xl bg-black/15 max-w-xl">
              <div className="mx-auto w-full max-w-[550px] p-10">
                <p className="mb-10">
                  Completeaza acest formular, iar noi vom raspunde cat de repede
                  putem.
                </p>
                <form onSubmit={handleSubmit}>
                  <div className="-mx-3 flex flex-wrap">
                    <div className="w-full px-3 sm:w-1/2">
                      <div className="mb-5">
                        <label
                          htmlFor="nume"
                          className="mb-4 block text-base font-medium text-white/65"
                        >
                          Nume
                        </label>
                        <input
                          type="text"
                          name="nume"
                          id="nume"
                          onChange={(e) => setNume(e.target.value)}
                          className="w-full rounded-lg border border-white/65 bg-transparent p-3 text-base font-medium text-white outline-none focus:border-[#0bc77f] focus:shadow-md caret-[#0bc77f]"
                        />
                      </div>
                    </div>
                    <div className="w-full px-3 sm:w-1/2">
                      <div className="mb-5">
                        <label
                          htmlFor="phone"
                          className="mb-4 block text-base font-medium text-white/65"
                        >
                          Telefon
                        </label>
                        <input
                          type="tel"
                          name="phone"
                          id="phone"
                          onChange={(e) => setPhone(e.target.value)}
                          className="w-full rounded-lg border border-white/65 bg-transparent p-3 text-base font-medium text-white outline-none focus:border-[#0bc77f] focus:shadow-md caret-[#0bc77f]"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mb-5">
                    <label
                      htmlFor="email"
                      className="mb-4 block text-base font-medium text-white/65"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      className="w-full rounded-lg border border-white/65 bg-transparent p-3 text-base font-medium text-white outline-none focus:border-[#0bc77f] focus:shadow-md caret-[#0bc77f]"
                    />
                  </div>
                  <div className="mb-5">
                    <label
                      htmlFor="message"
                      className="mb-4 block text-base font-medium text-white/65"
                    >
                      Mesaj
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      onChange={(e) => setMessage(e.target.value)}
                      rows={4}
                      className="w-full rounded-lg border border-white/65 bg-transparent p-3 text-base font-medium text-white outline-none focus:border-[#0bc77f] focus:shadow-md caret-[#0bc77f]"
                    ></textarea>
                  </div>

                  <div>
                    {showRecaptcha && (
                      <div className="my-3 flex justify-center">
                        <ReCAPTCHA
                          sitekey={recaptchaKey}
                          onChange={onRecaptchaChange}
                        />
                      </div>
                    )}
                    <button className="rounded-lg bg-white py-3 px-8 text-center text-base text-[#1a2224] outline-none font-bold flex items-center border-b-gray-300 border-b-4 w-full justify-center button-c">
                      Trimite
                      <LiaLongArrowAltRightSolid className="inline-block ml-1 btnci" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <GridPattern
            numSquares={30}
            maxOpacity={0.1}
            duration={3}
            repeatDelay={1}
            className={cn(
              "[mask-image:radial-gradient(500px_circle_at_center,white,transparent)]",
              "inset-x-0 inset-y-[-30%] h-[200%] skew-y-12"
            )}
          />
        </div>
      )}
    </>
  );
}
