import React, { useEffect, useState } from "react";
import { FeaturesSectionDemo } from "../components/features-section";

export default function Servicii() {
  const [isRendering, setIsRendering] = useState(true);

  useEffect(() => {
    setTimeout(() => setIsRendering(false), 1);
  }, []);
  return (
    <div>
      {isRendering && <div></div>}
      {!isRendering && (
        <div className="mt-28">
          <FeaturesSectionDemo />
        </div>
      )}
    </div>
  );
}
